@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap"); /* CSS Reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* CSS Reset Einde */
body {
  font-family: "Source Sans Pro", serif;
  background: #2A2A2A;
  color: #ffffff;
  font-size: 48px;
  line-height: 1.5em;
  padding: 96px;
}

.fullScreenReclame {
  margin: -96px;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.fullScreenReclame.isLogo {
  background: #ffffff;
  padding: 120px;
  box-sizing: border-box;
}
.fullScreenReclame img {
  max-width: 100%;
  max-height: 100%;
}

.logo_bg {
  width: 400px;
  height: 230px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.logo_bg img {
  width: 100%;
  opacity: 0.2;
}

#content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 96px - 96px);
  z-index: 2;
  position: relative;
}

header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}
header .clock {
  font-weight: bold;
}

section {
  z-index: 2;
  position: relative;
  flex: 100% 1 1;
  display: flex;
  flex-direction: column;
}

article {
  position: relative;
  display: flex;
  border-radius: 16px;
  align-items: center;
  background: #ffffff;
  color: #2A2A2A;
  padding: 32px 40px;
  margin-bottom: 32px;
}
article.footerTegel {
  padding: 16px 40px;
}
article.footerTegel .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
article.footerTegel .icon img {
  max-width: 100px;
}
article .starttijd {
  color: #297CBB;
}
article .icon {
  flex: 200px 0 0;
  text-align: center;
}
article .icon .icon_icon {
  line-height: 0;
}
article .icon .icon_icon img {
  max-width: 112px;
}
article .icon .icon_description {
  font-size: 28px;
  line-height: 1.25em;
}
article .space {
  flex: 24px 0 0;
}
article .content {
  flex: 100% 1 1;
}
article .naam {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 16px;
  line-height: 1.25em;
}
article .locatie,
article .starttijd {
  font-size: 32px;
  line-height: 1.25em;
}

.temp_foto {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 32px;
  margin-bottom: 32px;
  background: #ffffff;
  text-align: center;
  padding: 50px;
  line-height: 0;
}
.temp_foto img {
  max-width: 80%;
}

.reclameBlok {
  display: flex;
  flex: 100% 1 1;
  justify-content: center;
  align-items: center;
}
.reclameBlok .img {
  overflow: hidden;
  border-radius: 16px;
  line-height: 0;
}
.reclameBlok .img.isLogo {
  background: #ffffff;
  padding: 100px;
}
.reclameBlok .img img {
  max-width: 100%;
  max-height: 100%;
}

.noConnection article {
  display: block;
  text-align: center;
}

