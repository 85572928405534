@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
@import "partials/reset";
@import "partials/project";


body {
  font-family: $font-family;
  background: $color-dark-bg;
  color:#ffffff;
  font-size:48px;
  line-height:1.5em;

  padding:96px;
}

.fullScreenReclame {
  margin:-96px;
  background: #000000;
  display:flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  width:100vw;

  &.isLogo {
    background: #ffffff;
    padding:120px;
    box-sizing:border-box;
  }

  img {
    max-width:100%;
    max-height:100%;
  }

}

.logo_bg {
  width:400px;
  height:230px;
  position:absolute;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
  z-index:1;

  img {
    width:100%;
    opacity:0.2;
  }
}

#content {
    display:flex;
    flex-direction: column;
    min-height: calc(100vh - 96px - 96px);
    z-index:2;
    position:relative;
}

header {
  display:flex;
  justify-content: space-between;

  margin-bottom:48px;

  .clock {
    font-weight: bold;
  }
}

section {
    z-index:2;
    position:relative;

  flex:100% 1 1;
  display:flex;
  flex-direction: column;

}

article {
  position:relative;
  display:flex;
  border-radius:16px;
  align-items: center;
  background: #ffffff;
  color: $color-dark-bg;
  padding:32px 40px;
  margin-bottom:32px;

  &.footerTegel {
    padding: 16px 40px;

    .icon {
      display:flex;
      align-items: center;
      justify-content: center;
      img { max-width:100px; }
    }
  }

  .starttijd {
    color: $color-primary;
  }

  .icon {
    flex:200px 0 0;
    text-align: center;

    .icon_icon {
        line-height:0;
        img {
        max-width: 112px;
        }
    }

    .icon_description {
        font-size: 28px;
        line-height:1.25em;
    }
  }

  .space {
    flex: 24px 0 0;
  }

  .content {
    flex:100% 1 1;
  }

  .naam {
    font-size:60px;
    font-weight:bold;
    margin-bottom:16px;
    line-height:1.25em;
  }

  .locatie,
  .starttijd {
    font-size:32px;
    line-height:1.25em;

  }
}

.temp_foto {
  position:relative;
  border-radius:16px;
  overflow:hidden;
  margin-top:32px;
  margin-bottom:32px;
  background: #ffffff;
  text-align: center;
  padding:50px;

  line-height:0;
  img {
    max-width:80%;
  }
}

.reclameBlok {
  display:flex;
  flex:100% 1 1;
  justify-content: center;
  align-items: center;

  .img {
    overflow:hidden;
    border-radius: 16px;
    line-height:0;

    &.isLogo {
      background: #ffffff;
      padding:100px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.noConnection {
    article {
        display:block;
        text-align: center;
    }
}

